import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { BlogArticleItemFragmentDoc } from 'graphql/blogArticle/BlogArticleItem.generated';
export type StaticBlogRecommendationFragment = {
  __typename?: 'StaticBlogRecommendation';
  title: string;
  articles: Array<{
    __typename?: 'BlogArticleItem';
    leadText: string;
    mainTitle: string;
    tags?: Array<string> | null;
    date?: any | null;
    hero: {
      __typename?: 'Image';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
    author: {
      __typename?: 'EmployeeBox';
      name?: string | null;
      image?: {
        __typename?: 'Image';
        alt: string;
        description?: string | null;
        copyright?: string | null;
        resized: Array<{
          __typename?: 'ResizedImage';
          url: string;
          id: string;
        }>;
      } | null;
    };
    url: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
      objectId?: string | null;
    };
  }>;
};

export const StaticBlogRecommendationFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'staticBlogRecommendation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StaticBlogRecommendation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'articles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'blogArticleItem' },
                },
              ],
            },
          },
        ],
      },
    },
    ...BlogArticleItemFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
