import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { InternalLinkFragmentFragmentDoc } from 'graphql/link/InternalLink.generated';
import { DisrupterFragmentDoc } from 'graphql/fragments/Disrupter.generated';
import { ResizedImageWithMetaFragmentDoc } from 'graphql/image/ResizedImageWithMeta.generated';
import { RoutePointFragmentDoc } from 'graphql/fragments/ProductGalleryWithMap.generated';
export type StaticProductRecommendationFragment = {
  __typename?: 'StaticProductRecommendation';
  name?: string | null;
  fontColor?: string | null;
  backgroundColor?: string | null;
  items: Array<{
    __typename?: 'StaticProductRecommendationItem';
    name: string;
    teaser: string;
    route: Array<string>;
    breadcrumbs?: Array<string> | null;
    bookmarked: boolean;
    link: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
      objectId?: string | null;
    };
    disrupters?: Array<{
      __typename?: 'DisrupterObject';
      text: string;
      fontColor?: string | null;
      backgroundColor?: string | null;
    }> | null;
    features: Array<{
      __typename?: 'ProductFeature';
      id: string;
      name: string;
      enabled?: boolean | null;
    }>;
    image: {
      __typename?: 'Image';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
    routePoints?: Array<{
      __typename?: 'RoutePoint';
      name: string;
      coordinates: {
        __typename?: 'GeoCoordinates';
        latitude: number;
        longitude: number;
      };
    }> | null;
  }>;
  productFilters?: Array<{
    __typename?: 'ProductFilter';
    objectId: string;
    objectName: string;
  }> | null;
};

export type ProductFiltersFragment = {
  __typename?: 'ProductFilter';
  objectId: string;
  objectName: string;
};

export type StaticProductRecommendationItemFragment = {
  __typename?: 'StaticProductRecommendationItem';
  name: string;
  teaser: string;
  route: Array<string>;
  breadcrumbs?: Array<string> | null;
  bookmarked: boolean;
  link: {
    __typename?: 'InternalLink';
    caption?: string | null;
    uri: string;
    targetPageType: Types.PageType;
    objectId?: string | null;
  };
  disrupters?: Array<{
    __typename?: 'DisrupterObject';
    text: string;
    fontColor?: string | null;
    backgroundColor?: string | null;
  }> | null;
  features: Array<{
    __typename?: 'ProductFeature';
    id: string;
    name: string;
    enabled?: boolean | null;
  }>;
  image: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
  routePoints?: Array<{
    __typename?: 'RoutePoint';
    name: string;
    coordinates: {
      __typename?: 'GeoCoordinates';
      latitude: number;
      longitude: number;
    };
  }> | null;
};

export const StaticProductRecommendationItemFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'staticProductRecommendationItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StaticProductRecommendationItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teaser' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'internalLinkFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disrupters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'disrupter' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '364' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '315' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '468' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '388' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'route' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'routePoints' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'routePoint' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'breadcrumbs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bookmarked' } },
        ],
      },
    },
    ...InternalLinkFragmentFragmentDoc.definitions,
    ...DisrupterFragmentDoc.definitions,
    ...ResizedImageWithMetaFragmentDoc.definitions,
    ...RoutePointFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const ProductFiltersFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productFilters' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductFilter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'objectId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'objectName' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
export const StaticProductRecommendationFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'staticProductRecommendation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StaticProductRecommendation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'staticProductRecommendationItem',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productFilters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'productFilters' },
                },
              ],
            },
          },
        ],
      },
    },
    ...StaticProductRecommendationItemFragmentDoc.definitions,
    ...ProductFiltersFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
