import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { MarketingRecommendationItemFragmentDoc } from 'graphql/marketing/MarketingRecommendationItem.generated';
export type MarketingRecommendationFragment = {
  __typename?: 'MarketingRecommendation';
  fontColor?: string | null;
  titleFontColor?: string | null;
  backgroundColor?: string | null;
  name?: string | null;
  displayType?: string | null;
  items: Array<{
    __typename?: 'MarketingRecommendationItem';
    title: string;
    subtitle: string;
    link:
      | {
          __typename?: 'ExternalLink';
          openMethod: Types.OpenLinkMethod;
          url: string;
          caption?: string | null;
        }
      | {
          __typename?: 'InternalLink';
          caption?: string | null;
          uri: string;
          targetPageType: Types.PageType;
          objectId?: string | null;
        };
    preview:
      | {
          __typename?: 'ExternalMediaItem';
          resource: string;
          title: string;
          fullScreen: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          preview: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }
      | {
          __typename?: 'ImageMediaItem';
          title: string;
          fullScreen: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          image: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }
      | {
          __typename?: 'MusicMediaItem';
          resource: string;
          title: string;
          fullScreen: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          preview: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }
      | {
          __typename?: 'PdfMediaItem';
          resource: string;
          title: string;
          fullScreen: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          preview: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }
      | {
          __typename?: 'VideoMediaItem';
          resource: string;
          title: string;
          fullScreen: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          preview: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        };
  }>;
};

export const MarketingRecommendationFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'marketingRecommendation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketingRecommendation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'fontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleFontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'marketingRecommendationItem' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'displayType' } },
        ],
      },
    },
    ...MarketingRecommendationItemFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
