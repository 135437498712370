import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { ResizedImageWithMetaFragmentDoc } from 'graphql/image/ResizedImageWithMeta.generated';
export type MediaGalleryFragment = {
  __typename?: 'MediaGallery';
  mediaItems: Array<
    | {
        __typename?: 'ExternalMediaItem';
        title: string;
        resource: string;
        preview: {
          __typename?: 'Image';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
    | {
        __typename?: 'ImageMediaItem';
        title: string;
        image: {
          __typename?: 'Image';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
        preview: {
          __typename?: 'Image';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
    | {
        __typename?: 'MusicMediaItem';
        title: string;
        resource: string;
        preview: {
          __typename?: 'Image';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
    | {
        __typename?: 'PdfMediaItem';
        title: string;
        resource: string;
        preview: {
          __typename?: 'Image';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
    | {
        __typename?: 'VideoMediaItem';
        title: string;
        resource: string;
        copyright?: string | null;
        ratio?: number | null;
        preview: {
          __typename?: 'Image';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
  >;
};

export type MediaExternalMediaItemFragment = {
  __typename?: 'ExternalMediaItem';
  title: string;
  resource: string;
  preview: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
};

export type MediaImageMediaItemFragment = {
  __typename?: 'ImageMediaItem';
  title: string;
  image: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
  preview: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
};

export type MediaMusicMediaItemFragment = {
  __typename?: 'MusicMediaItem';
  title: string;
  resource: string;
  preview: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
};

export type MediaPdfMediaItemFragment = {
  __typename?: 'PdfMediaItem';
  title: string;
  resource: string;
  preview: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
};

export type MediaVideoMediaItemFragment = {
  __typename?: 'VideoMediaItem';
  title: string;
  resource: string;
  copyright?: string | null;
  ratio?: number | null;
  preview: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
};

export type MediaFragment =
  | MediaExternalMediaItemFragment
  | MediaImageMediaItemFragment
  | MediaMusicMediaItemFragment
  | MediaPdfMediaItemFragment
  | MediaVideoMediaItemFragment;

export type ImageMediaFragment = {
  __typename?: 'ImageMediaItem';
  title: string;
  image: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
  preview: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
};

export type VideoMediaFragment = {
  __typename?: 'VideoMediaItem';
  title: string;
  resource: string;
  copyright?: string | null;
  ratio?: number | null;
  preview: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
};

export type ExternalMediaItemFragment = {
  __typename?: 'ExternalMediaItem';
  title: string;
  resource: string;
  preview: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
};

export type MusicMediaItemFragment = {
  __typename?: 'MusicMediaItem';
  title: string;
  resource: string;
  preview: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
};

export type PdfMediaItemFragment = {
  __typename?: 'PdfMediaItem';
  title: string;
  resource: string;
  preview: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
};

export const ImageMediaFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'imageMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ImageMediaItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'mediaFull' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'preview' },
            name: { kind: 'Name', value: 'image' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'mediaPreview' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const VideoMediaFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'videoMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VideoMediaItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'copyright' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ratio' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'mediaPreview' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const ExternalMediaItemFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'externalMediaItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExternalMediaItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resource' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'mediaPreview' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const MusicMediaItemFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'musicMediaItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MusicMediaItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resource' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'mediaPreview' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const PdfMediaItemFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'pdfMediaItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PdfMediaItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resource' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'mediaPreview' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const MediaFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'media' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Media' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'imageMedia' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'videoMedia' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'externalMediaItem' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'musicMediaItem' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'pdfMediaItem' },
          },
        ],
      },
    },
    ...ImageMediaFragmentDoc.definitions,
    ...VideoMediaFragmentDoc.definitions,
    ...ExternalMediaItemFragmentDoc.definitions,
    ...MusicMediaItemFragmentDoc.definitions,
    ...PdfMediaItemFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const MediaGalleryFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'mediaGallery' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MediaGallery' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'media' },
                },
              ],
            },
          },
        ],
      },
    },
    ...MediaFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
