import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { ResizedImageWithMetaFragmentDoc } from 'graphql/image/ResizedImageWithMeta.generated';
import { LinkFragmentFragmentDoc } from 'graphql/link/Link.generated';
export type ContactModuleFragment = {
  __typename?: 'ContactModuleComponent';
  flagline?: string | null;
  mainTitle?: string | null;
  contactTypeBases: Array<
    | {
        __typename?: 'ContactType';
        mainTitle?: string | null;
        icon?: {
          __typename?: 'Image';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        } | null;
        link?:
          | {
              __typename?: 'ExternalLink';
              openMethod: Types.OpenLinkMethod;
              url: string;
              caption?: string | null;
            }
          | {
              __typename?: 'InternalLink';
              caption?: string | null;
              uri: string;
              targetPageType: Types.PageType;
              objectId?: string | null;
            }
          | null;
      }
    | {
        __typename?: 'ContactTypePhone';
        mainTitle?: string | null;
        phone?: string | null;
        icon?: {
          __typename?: 'Image';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        } | null;
      }
  >;
};

export type ContactTypeFragment = {
  __typename?: 'ContactType';
  mainTitle?: string | null;
  icon?: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  } | null;
  link?:
    | {
        __typename?: 'ExternalLink';
        openMethod: Types.OpenLinkMethod;
        url: string;
        caption?: string | null;
      }
    | {
        __typename?: 'InternalLink';
        caption?: string | null;
        uri: string;
        targetPageType: Types.PageType;
        objectId?: string | null;
      }
    | null;
};

export type ContactTypePhoneFragment = {
  __typename?: 'ContactTypePhone';
  mainTitle?: string | null;
  phone?: string | null;
  icon?: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  } | null;
};

export const ContactTypeFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'contactType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'mainTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '100' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '70' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '100' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '70' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'linkFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
    ...LinkFragmentFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const ContactTypePhoneFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'contactTypePhone' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactTypePhone' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'mainTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '100' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '70' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '100' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '70' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const ContactModuleFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'contactModule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactModuleComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'flagline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactTypeBases' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'contactType' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'contactTypePhone' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ContactTypeFragmentDoc.definitions,
    ...ContactTypePhoneFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
