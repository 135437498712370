import {
  ApolloQueryResult,
  OperationVariables,
  QueryOptions,
} from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
import { IRequestContext } from '@hotelplan/libs.context.req-ctx/RequestContext.types';
import {
  galleryWithMapFull,
  galleryWithMapPreview,
  heroCriteria,
  marketingRecommendation,
  mediaFull,
  mediaPreview,
} from 'components/domain/criterias';
import {
  GetComponentsDocument,
  GetComponentsQuery,
  GetComponentsQueryVariables,
  useGetComponentsQuery,
} from 'graphql/components/GetComponents.generated';

export const useDynamicComponentsWithCriteria = (
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetComponentsQuery,
    GetComponentsQueryVariables
  >
) => {
  return useGetComponentsQuery({
    ...baseOptions,
    variables: {
      heroCriteria,
      mediaPreview,
      mediaFull,
      galleryWithMapPreview,
      galleryWithMapFull,
      marketingRecommendation,
      ...baseOptions.variables,
    },
  });
};

export const getDynamicComponentsWithCriteria = (
  queryCtx: <T = any, TVariables = OperationVariables>(
    queryOptions: QueryOptions<
      Omit<TVariables, 'context'> & {
        context?: Partial<IRequestContext<any, any>>;
      },
      T
    >
  ) => Promise<ApolloQueryResult<T>>,
  variables: Omit<GetComponentsQueryVariables, 'context'>
) => {
  return queryCtx<GetComponentsQuery, GetComponentsQueryVariables>({
    query: GetComponentsDocument,
    variables: {
      heroCriteria,
      mediaPreview,
      mediaFull,
      galleryWithMapPreview,
      galleryWithMapFull,
      marketingRecommendation,
      ...variables,
    },
  });
};
