import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { OpeningHoursFragmentDoc } from './OpeningHours.generated';
import { CoordinatesFragmentDoc } from './Coordinates.generated';
import { ResizedImageWithMetaFragmentDoc } from 'graphql/image/ResizedImageWithMeta.generated';
import { AddressDetailsFragmentDoc } from './AddressDetails.generated';
import { ExternalLinkFragmentDoc } from 'graphql/link/ExternalLink.generated';
import { RequestButtonFragmentDoc } from 'graphql/requestButton/RequestButton.generated';
export type AgencyContactComponentFragment = {
  __typename?: 'AgencyContactComponent';
  title: string;
  agencyName: string;
  timezone: string;
  openingHours: Array<{
    __typename?: 'OpeningHours';
    open: boolean;
    shifts: Array<{ __typename?: 'Shift'; from: string; to: string }>;
  }>;
  coordinates: {
    __typename?: 'GeoCoordinates';
    latitude: number;
    longitude: number;
  };
  image?: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  } | null;
  address: {
    __typename?: 'AddressDetails';
    city?: string | null;
    country?: string | null;
    region?: string | null;
    street?: string | null;
    zip?: string | null;
  };
  phone: {
    __typename?: 'ExternalLink';
    openMethod: Types.OpenLinkMethod;
    url: string;
    caption?: string | null;
  };
  requestButton: {
    __typename?: 'RequestButtonComponent';
    fontColor?: string | null;
    backgroundColor?: string | null;
    caption: string;
    link: {
      __typename?: 'ExternalLink';
      openMethod: Types.OpenLinkMethod;
      url: string;
      caption?: string | null;
    };
  };
};

export const AgencyContactComponentFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'agencyContactComponent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AgencyContactComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'openingHours' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'openingHours' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'coordinates' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '364' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '315' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '370' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '370' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'addressDetails' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agencyName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phone' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'externalLink' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestButton' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'requestButton' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
        ],
      },
    },
    ...OpeningHoursFragmentDoc.definitions,
    ...CoordinatesFragmentDoc.definitions,
    ...ResizedImageWithMetaFragmentDoc.definitions,
    ...AddressDetailsFragmentDoc.definitions,
    ...ExternalLinkFragmentDoc.definitions,
    ...RequestButtonFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
