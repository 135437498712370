import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { ResizedImageWithMetaFragmentDoc } from 'graphql/image/ResizedImageWithMeta.generated';
export type HeroMediaGalleryFragment = {
  __typename?: 'HeroMediaGallery';
  flagline?: string | null;
  fontColor?: string | null;
  mainTitle?: string | null;
  mediaItems: Array<
    | {
        __typename?: 'ImageMediaItem';
        title: string;
        image: {
          __typename?: 'Image';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
    | {
        __typename?: 'VideoMediaItem';
        title: string;
        resource: string;
        copyright?: string | null;
        ratio?: number | null;
        preview: {
          __typename?: 'Image';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
  >;
};

export type HeroMediaImageMediaItemFragment = {
  __typename?: 'ImageMediaItem';
  title: string;
  image: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
};

export type HeroMediaVideoMediaItemFragment = {
  __typename?: 'VideoMediaItem';
  title: string;
  resource: string;
  copyright?: string | null;
  ratio?: number | null;
  preview: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
};

export type HeroMediaFragment =
  | HeroMediaImageMediaItemFragment
  | HeroMediaVideoMediaItemFragment;

export type HeroImageMediaFragment = {
  __typename?: 'ImageMediaItem';
  title: string;
  image: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
};

export type HeroVideoMediaFragment = {
  __typename?: 'VideoMediaItem';
  title: string;
  resource: string;
  copyright?: string | null;
  ratio?: number | null;
  preview: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
};

export const HeroImageMediaFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'heroImageMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ImageMediaItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'heroCriteria' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const HeroVideoMediaFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'heroVideoMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VideoMediaItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'copyright' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ratio' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'heroCriteria' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const HeroMediaFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'heroMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HeroMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'heroImageMedia' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'heroVideoMedia' },
          },
        ],
      },
    },
    ...HeroImageMediaFragmentDoc.definitions,
    ...HeroVideoMediaFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const HeroMediaGalleryFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'heroMediaGallery' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HeroMediaGallery' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'heroMedia' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'flagline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainTitle' } },
        ],
      },
    },
    ...HeroMediaFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
