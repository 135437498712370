import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { TextFragmentDoc } from 'graphql/fragments/Text.generated';
import { UspsFragmentDoc } from 'graphql/fragments/Usp.generated';
import { FaqFragmentDoc } from 'graphql/fragments/Faq.generated';
import { HeroMediaGalleryFragmentDoc } from 'graphql/fragments/HeroMedia.generated';
import { MediaGalleryFragmentDoc } from 'graphql/fragments/Media.generated';
import { StaticGeoRecommendationFragmentDoc } from 'graphql/recommendations/StaticGeoRecommendation.generated';
import { StaticThemeRecommendationFragmentDoc } from 'graphql/recommendations/StaticThemeRecommendation.generated';
import { MarketingRecommendationFragmentDoc } from 'graphql/recommendations/MarketingRecommendation.generated';
import { StaticProductRecommendationFragmentDoc } from 'graphql/recommendations/StaticProductRecommendation.generated';
import { ContactModuleFragmentDoc } from 'graphql/fragments/ContactTypeBases.generated';
import { ProductGalleryWithMapFragmentDoc } from 'graphql/fragments/ProductGalleryWithMap.generated';
import { RequestButtonFragmentDoc } from 'graphql/requestButton/RequestButton.generated';
import { AgencyRecommendationFragmentDoc } from 'graphql/recommendations/AgencyRecommendation.generated';
import { AgencyContactComponentFragmentDoc } from 'graphql/fragments/AgencyContactComponent.generated';
import { StaticBlogRecommendationFragmentDoc } from 'graphql/recommendations/StaticBlogRecommendation.generated';
import { BenefitsFragmentDoc } from 'graphql/fragments/Benefits.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetComponentsQueryVariables = Types.Exact<{
  context: Types.RequestContextInput;
  input: Types.ComponentsQueryInput;
  long?: Types.InputMaybe<Types.Scalars['Boolean']>;
  heroCriteria?: Array<Types.ImageCriteriaInput> | Types.ImageCriteriaInput;
  mediaPreview?: Array<Types.ImageCriteriaInput> | Types.ImageCriteriaInput;
  mediaFull?: Array<Types.ImageCriteriaInput> | Types.ImageCriteriaInput;
  marketingRecommendation?:
    | Array<Types.ImageCriteriaInput>
    | Types.ImageCriteriaInput;
  galleryWithMapPreview?:
    | Array<Types.ImageCriteriaInput>
    | Types.ImageCriteriaInput;
  galleryWithMapFull?:
    | Array<Types.ImageCriteriaInput>
    | Types.ImageCriteriaInput;
  blogCriteria?: Array<Types.ImageCriteriaInput> | Types.ImageCriteriaInput;
}>;

export type GetComponentsQuery = {
  __typename?: 'Query';
  components: Array<
    | { __typename?: 'AccordionComponent' }
    | {
        __typename?: 'AgencyContactComponent';
        title: string;
        agencyName: string;
        timezone: string;
        openingHours: Array<{
          __typename?: 'OpeningHours';
          open: boolean;
          shifts: Array<{ __typename?: 'Shift'; from: string; to: string }>;
        }>;
        coordinates: {
          __typename?: 'GeoCoordinates';
          latitude: number;
          longitude: number;
        };
        image?: {
          __typename?: 'Image';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        } | null;
        address: {
          __typename?: 'AddressDetails';
          city?: string | null;
          country?: string | null;
          region?: string | null;
          street?: string | null;
          zip?: string | null;
        };
        phone: {
          __typename?: 'ExternalLink';
          openMethod: Types.OpenLinkMethod;
          url: string;
          caption?: string | null;
        };
        requestButton: {
          __typename?: 'RequestButtonComponent';
          fontColor?: string | null;
          backgroundColor?: string | null;
          caption: string;
          link: {
            __typename?: 'ExternalLink';
            openMethod: Types.OpenLinkMethod;
            url: string;
            caption?: string | null;
          };
        };
      }
    | {
        __typename?: 'AgencyRecommendation';
        title: string;
        strictFontColor: string;
        strictBackgroundColor: string;
        items: Array<{
          __typename?: 'AgencyRecommendationItem';
          name: string;
          city: string;
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
            objectId?: string | null;
          };
          image: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }>;
      }
    | { __typename?: 'BD4TravelRecommendationGroup' }
    | {
        __typename?: 'BenefitsComponent';
        text: string;
        buttonLabel: string;
        items: Array<{ __typename?: 'BenefitItem'; text?: string | null }>;
        buttonLink:
          | {
              __typename?: 'ExternalLink';
              openMethod: Types.OpenLinkMethod;
              url: string;
              caption?: string | null;
            }
          | {
              __typename?: 'InternalLink';
              caption?: string | null;
              uri: string;
              targetPageType: Types.PageType;
              objectId?: string | null;
            };
      }
    | { __typename?: 'BlogArticleRecommendation' }
    | { __typename?: 'BrandsComponent' }
    | { __typename?: 'ClimateComponent' }
    | {
        __typename?: 'ContactModuleComponent';
        flagline?: string | null;
        mainTitle?: string | null;
        contactTypeBases: Array<
          | {
              __typename?: 'ContactType';
              mainTitle?: string | null;
              icon?: {
                __typename?: 'Image';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              } | null;
              link?:
                | {
                    __typename?: 'ExternalLink';
                    openMethod: Types.OpenLinkMethod;
                    url: string;
                    caption?: string | null;
                  }
                | {
                    __typename?: 'InternalLink';
                    caption?: string | null;
                    uri: string;
                    targetPageType: Types.PageType;
                    objectId?: string | null;
                  }
                | null;
            }
          | {
              __typename?: 'ContactTypePhone';
              mainTitle?: string | null;
              phone?: string | null;
              icon?: {
                __typename?: 'Image';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              } | null;
            }
        >;
      }
    | { __typename?: 'EmployeesComponent' }
    | {
        __typename?: 'FaqComponent';
        items: Array<{
          __typename?: 'TextComponent';
          title: string;
          text: string;
          type: string;
          textWidth?: string | null;
          backgroundColor?: string | null;
          bottomPadding?: string | null;
          topPadding?: string | null;
          fontColor?: string | null;
          backgroundImage?: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          } | null;
        }>;
      }
    | { __typename?: 'FlightWithPriceRecommendation' }
    | { __typename?: 'FlightWithoutPriceRecommendation' }
    | { __typename?: 'GeoChildrenComponent' }
    | { __typename?: 'GeoGalleryComponent' }
    | { __typename?: 'GeoRecommendation' }
    | {
        __typename?: 'HeroMediaGallery';
        flagline?: string | null;
        fontColor?: string | null;
        mainTitle?: string | null;
        mediaItems: Array<
          | {
              __typename?: 'ImageMediaItem';
              title: string;
              image: {
                __typename?: 'Image';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
            }
          | {
              __typename?: 'VideoMediaItem';
              title: string;
              resource: string;
              copyright?: string | null;
              ratio?: number | null;
              preview: {
                __typename?: 'Image';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
            }
        >;
      }
    | { __typename?: 'ImageTextComponent' }
    | { __typename?: 'LinkListComponent' }
    | {
        __typename?: 'MarketingRecommendation';
        fontColor?: string | null;
        titleFontColor?: string | null;
        backgroundColor?: string | null;
        name?: string | null;
        displayType?: string | null;
        items: Array<{
          __typename?: 'MarketingRecommendationItem';
          title: string;
          subtitle: string;
          link:
            | {
                __typename?: 'ExternalLink';
                openMethod: Types.OpenLinkMethod;
                url: string;
                caption?: string | null;
              }
            | {
                __typename?: 'InternalLink';
                caption?: string | null;
                uri: string;
                targetPageType: Types.PageType;
                objectId?: string | null;
              };
          preview:
            | {
                __typename?: 'ExternalMediaItem';
                resource: string;
                title: string;
                fullScreen: {
                  __typename?: 'Image';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
                preview: {
                  __typename?: 'Image';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
              }
            | {
                __typename?: 'ImageMediaItem';
                title: string;
                fullScreen: {
                  __typename?: 'Image';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
                image: {
                  __typename?: 'Image';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
              }
            | {
                __typename?: 'MusicMediaItem';
                resource: string;
                title: string;
                fullScreen: {
                  __typename?: 'Image';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
                preview: {
                  __typename?: 'Image';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
              }
            | {
                __typename?: 'PdfMediaItem';
                resource: string;
                title: string;
                fullScreen: {
                  __typename?: 'Image';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
                preview: {
                  __typename?: 'Image';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
              }
            | {
                __typename?: 'VideoMediaItem';
                resource: string;
                title: string;
                fullScreen: {
                  __typename?: 'Image';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
                preview: {
                  __typename?: 'Image';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
              };
        }>;
      }
    | { __typename?: 'MarketingTeaserComponent' }
    | {
        __typename?: 'MediaGallery';
        mediaItems: Array<
          | {
              __typename?: 'ExternalMediaItem';
              title: string;
              resource: string;
              preview: {
                __typename?: 'Image';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
            }
          | {
              __typename?: 'ImageMediaItem';
              title: string;
              image: {
                __typename?: 'Image';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
              preview: {
                __typename?: 'Image';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
            }
          | {
              __typename?: 'MusicMediaItem';
              title: string;
              resource: string;
              preview: {
                __typename?: 'Image';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
            }
          | {
              __typename?: 'PdfMediaItem';
              title: string;
              resource: string;
              preview: {
                __typename?: 'Image';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
            }
          | {
              __typename?: 'VideoMediaItem';
              title: string;
              resource: string;
              copyright?: string | null;
              ratio?: number | null;
              preview: {
                __typename?: 'Image';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
            }
        >;
      }
    | { __typename?: 'NewsArticlesComponent' }
    | { __typename?: 'NewsletterSignupComponent' }
    | { __typename?: 'OfferButtonComponent' }
    | { __typename?: 'OffersMapPlaceholderComponent' }
    | {
        __typename?: 'ProductGalleryWithMapComponent';
        title: string;
        images: Array<{
          __typename?: 'ProductImageItem';
          productName: string;
          coordinates: {
            __typename?: 'GeoCoordinates';
            latitude: number;
            longitude: number;
          };
          image?: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          } | null;
          preview?: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          } | null;
          disrupters?: Array<{
            __typename?: 'DisrupterObject';
            text: string;
            fontColor?: string | null;
            backgroundColor?: string | null;
          }> | null;
        }>;
        routePoints?: Array<{
          __typename?: 'RoutePoint';
          name: string;
          coordinates: {
            __typename?: 'GeoCoordinates';
            latitude: number;
            longitude: number;
          };
        }> | null;
        relatedRoundtrips?: {
          __typename?: 'RelatedRoundtrips';
          coordinates?: {
            __typename?: 'GeoCoordinates';
            latitude: number;
            longitude: number;
          } | null;
          roundtrips?: Array<{
            __typename?: 'RelatedRoundtripItem';
            name: string;
            link: {
              __typename?: 'InternalLink';
              targetPageType: Types.PageType;
              uri: string;
            };
            image?: {
              __typename?: 'Image';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              resized: Array<{
                __typename?: 'ResizedImage';
                url: string;
                id: string;
              }>;
            } | null;
          }> | null;
        } | null;
      }
    | { __typename?: 'ProductRecommendation' }
    | {
        __typename?: 'RequestButtonComponent';
        fontColor?: string | null;
        backgroundColor?: string | null;
        caption: string;
        link: {
          __typename?: 'ExternalLink';
          openMethod: Types.OpenLinkMethod;
          url: string;
          caption?: string | null;
        };
      }
    | { __typename?: 'ResortTopHotelsComponent' }
    | { __typename?: 'SrlButtonPlaceholderComponent' }
    | { __typename?: 'SrlMapPinsComponent' }
    | {
        __typename?: 'StaticBlogRecommendation';
        title: string;
        articles: Array<{
          __typename?: 'BlogArticleItem';
          leadText: string;
          mainTitle: string;
          tags?: Array<string> | null;
          date?: any | null;
          hero: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          author: {
            __typename?: 'EmployeeBox';
            name?: string | null;
            image?: {
              __typename?: 'Image';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              resized: Array<{
                __typename?: 'ResizedImage';
                url: string;
                id: string;
              }>;
            } | null;
          };
          url: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
            objectId?: string | null;
          };
        }>;
      }
    | {
        __typename?: 'StaticGeoRecommendation';
        name?: string | null;
        displayNumber: number;
        strictFontColor: string;
        strictBackgroundColor: string;
        items: Array<{
          __typename?: 'StaticGeoRecommendationItem';
          id: string;
          title: string;
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
            objectId?: string | null;
          };
          image: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }>;
      }
    | {
        __typename?: 'StaticProductRecommendation';
        name?: string | null;
        fontColor?: string | null;
        backgroundColor?: string | null;
        items: Array<{
          __typename?: 'StaticProductRecommendationItem';
          name: string;
          teaser: string;
          route: Array<string>;
          breadcrumbs?: Array<string> | null;
          bookmarked: boolean;
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
            objectId?: string | null;
          };
          disrupters?: Array<{
            __typename?: 'DisrupterObject';
            text: string;
            fontColor?: string | null;
            backgroundColor?: string | null;
          }> | null;
          features: Array<{
            __typename?: 'ProductFeature';
            id: string;
            name: string;
            enabled?: boolean | null;
          }>;
          image: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          routePoints?: Array<{
            __typename?: 'RoutePoint';
            name: string;
            coordinates: {
              __typename?: 'GeoCoordinates';
              latitude: number;
              longitude: number;
            };
          }> | null;
        }>;
        productFilters?: Array<{
          __typename?: 'ProductFilter';
          objectId: string;
          objectName: string;
        }> | null;
      }
    | {
        __typename?: 'StaticThemeRecommendation';
        name?: string | null;
        displayType?: string | null;
        strictFontColor: string;
        strictBackgroundColor: string;
        items: Array<{
          __typename?: 'StaticThemeRecommendationItem';
          name: string;
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
            objectId?: string | null;
          };
          image: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }>;
      }
    | {
        __typename?: 'TextComponent';
        title: string;
        text: string;
        type: string;
        textWidth?: string | null;
        backgroundColor?: string | null;
        bottomPadding?: string | null;
        topPadding?: string | null;
        fontColor?: string | null;
        backgroundImage?: {
          __typename?: 'Image';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        } | null;
      }
    | { __typename?: 'ThemeRecommendation' }
    | {
        __typename?: 'UspBoxesComponent';
        uspTitle?: string | null;
        fontColor?: string | null;
        backgroundColor?: string | null;
        boxes: Array<{
          __typename?: 'UspBox';
          title?: string | null;
          subtitle?: string | null;
          text?: string | null;
          link?:
            | {
                __typename?: 'ExternalLink';
                openMethod: Types.OpenLinkMethod;
                url: string;
                caption?: string | null;
              }
            | {
                __typename?: 'InternalLink';
                caption?: string | null;
                uri: string;
                targetPageType: Types.PageType;
                objectId?: string | null;
              }
            | null;
          icon?: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          } | null;
        }>;
      }
  >;
};

export type DynamicComponentAccordionComponentFragment = {
  __typename?: 'AccordionComponent';
};

export type DynamicComponentAgencyContactComponentFragment = {
  __typename?: 'AgencyContactComponent';
  title: string;
  agencyName: string;
  timezone: string;
  openingHours: Array<{
    __typename?: 'OpeningHours';
    open: boolean;
    shifts: Array<{ __typename?: 'Shift'; from: string; to: string }>;
  }>;
  coordinates: {
    __typename?: 'GeoCoordinates';
    latitude: number;
    longitude: number;
  };
  image?: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  } | null;
  address: {
    __typename?: 'AddressDetails';
    city?: string | null;
    country?: string | null;
    region?: string | null;
    street?: string | null;
    zip?: string | null;
  };
  phone: {
    __typename?: 'ExternalLink';
    openMethod: Types.OpenLinkMethod;
    url: string;
    caption?: string | null;
  };
  requestButton: {
    __typename?: 'RequestButtonComponent';
    fontColor?: string | null;
    backgroundColor?: string | null;
    caption: string;
    link: {
      __typename?: 'ExternalLink';
      openMethod: Types.OpenLinkMethod;
      url: string;
      caption?: string | null;
    };
  };
};

export type DynamicComponentAgencyRecommendationFragment = {
  __typename?: 'AgencyRecommendation';
  title: string;
  strictFontColor: string;
  strictBackgroundColor: string;
  items: Array<{
    __typename?: 'AgencyRecommendationItem';
    name: string;
    city: string;
    link: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
      objectId?: string | null;
    };
    image: {
      __typename?: 'Image';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
  }>;
};

export type DynamicComponentBd4TravelRecommendationGroupFragment = {
  __typename?: 'BD4TravelRecommendationGroup';
};

export type DynamicComponentBenefitsComponentFragment = {
  __typename?: 'BenefitsComponent';
  text: string;
  buttonLabel: string;
  items: Array<{ __typename?: 'BenefitItem'; text?: string | null }>;
  buttonLink:
    | {
        __typename?: 'ExternalLink';
        openMethod: Types.OpenLinkMethod;
        url: string;
        caption?: string | null;
      }
    | {
        __typename?: 'InternalLink';
        caption?: string | null;
        uri: string;
        targetPageType: Types.PageType;
        objectId?: string | null;
      };
};

export type DynamicComponentBlogArticleRecommendationFragment = {
  __typename?: 'BlogArticleRecommendation';
};

export type DynamicComponentBrandsComponentFragment = {
  __typename?: 'BrandsComponent';
};

export type DynamicComponentClimateComponentFragment = {
  __typename?: 'ClimateComponent';
};

export type DynamicComponentContactModuleComponentFragment = {
  __typename?: 'ContactModuleComponent';
  flagline?: string | null;
  mainTitle?: string | null;
  contactTypeBases: Array<
    | {
        __typename?: 'ContactType';
        mainTitle?: string | null;
        icon?: {
          __typename?: 'Image';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        } | null;
        link?:
          | {
              __typename?: 'ExternalLink';
              openMethod: Types.OpenLinkMethod;
              url: string;
              caption?: string | null;
            }
          | {
              __typename?: 'InternalLink';
              caption?: string | null;
              uri: string;
              targetPageType: Types.PageType;
              objectId?: string | null;
            }
          | null;
      }
    | {
        __typename?: 'ContactTypePhone';
        mainTitle?: string | null;
        phone?: string | null;
        icon?: {
          __typename?: 'Image';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        } | null;
      }
  >;
};

export type DynamicComponentEmployeesComponentFragment = {
  __typename?: 'EmployeesComponent';
};

export type DynamicComponentFaqComponentFragment = {
  __typename?: 'FaqComponent';
  items: Array<{
    __typename?: 'TextComponent';
    title: string;
    text: string;
    type: string;
    textWidth?: string | null;
    backgroundColor?: string | null;
    bottomPadding?: string | null;
    topPadding?: string | null;
    fontColor?: string | null;
    backgroundImage?: {
      __typename?: 'Image';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    } | null;
  }>;
};

export type DynamicComponentFlightWithPriceRecommendationFragment = {
  __typename?: 'FlightWithPriceRecommendation';
};

export type DynamicComponentFlightWithoutPriceRecommendationFragment = {
  __typename?: 'FlightWithoutPriceRecommendation';
};

export type DynamicComponentGeoChildrenComponentFragment = {
  __typename?: 'GeoChildrenComponent';
};

export type DynamicComponentGeoGalleryComponentFragment = {
  __typename?: 'GeoGalleryComponent';
};

export type DynamicComponentGeoRecommendationFragment = {
  __typename?: 'GeoRecommendation';
};

export type DynamicComponentHeroMediaGalleryFragment = {
  __typename?: 'HeroMediaGallery';
  flagline?: string | null;
  fontColor?: string | null;
  mainTitle?: string | null;
  mediaItems: Array<
    | {
        __typename?: 'ImageMediaItem';
        title: string;
        image: {
          __typename?: 'Image';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
    | {
        __typename?: 'VideoMediaItem';
        title: string;
        resource: string;
        copyright?: string | null;
        ratio?: number | null;
        preview: {
          __typename?: 'Image';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
  >;
};

export type DynamicComponentImageTextComponentFragment = {
  __typename?: 'ImageTextComponent';
};

export type DynamicComponentLinkListComponentFragment = {
  __typename?: 'LinkListComponent';
};

export type DynamicComponentMarketingRecommendationFragment = {
  __typename?: 'MarketingRecommendation';
  fontColor?: string | null;
  titleFontColor?: string | null;
  backgroundColor?: string | null;
  name?: string | null;
  displayType?: string | null;
  items: Array<{
    __typename?: 'MarketingRecommendationItem';
    title: string;
    subtitle: string;
    link:
      | {
          __typename?: 'ExternalLink';
          openMethod: Types.OpenLinkMethod;
          url: string;
          caption?: string | null;
        }
      | {
          __typename?: 'InternalLink';
          caption?: string | null;
          uri: string;
          targetPageType: Types.PageType;
          objectId?: string | null;
        };
    preview:
      | {
          __typename?: 'ExternalMediaItem';
          resource: string;
          title: string;
          fullScreen: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          preview: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }
      | {
          __typename?: 'ImageMediaItem';
          title: string;
          fullScreen: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          image: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }
      | {
          __typename?: 'MusicMediaItem';
          resource: string;
          title: string;
          fullScreen: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          preview: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }
      | {
          __typename?: 'PdfMediaItem';
          resource: string;
          title: string;
          fullScreen: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          preview: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }
      | {
          __typename?: 'VideoMediaItem';
          resource: string;
          title: string;
          fullScreen: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          preview: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        };
  }>;
};

export type DynamicComponentMarketingTeaserComponentFragment = {
  __typename?: 'MarketingTeaserComponent';
};

export type DynamicComponentMediaGalleryFragment = {
  __typename?: 'MediaGallery';
  mediaItems: Array<
    | {
        __typename?: 'ExternalMediaItem';
        title: string;
        resource: string;
        preview: {
          __typename?: 'Image';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
    | {
        __typename?: 'ImageMediaItem';
        title: string;
        image: {
          __typename?: 'Image';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
        preview: {
          __typename?: 'Image';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
    | {
        __typename?: 'MusicMediaItem';
        title: string;
        resource: string;
        preview: {
          __typename?: 'Image';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
    | {
        __typename?: 'PdfMediaItem';
        title: string;
        resource: string;
        preview: {
          __typename?: 'Image';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
    | {
        __typename?: 'VideoMediaItem';
        title: string;
        resource: string;
        copyright?: string | null;
        ratio?: number | null;
        preview: {
          __typename?: 'Image';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }
  >;
};

export type DynamicComponentNewsArticlesComponentFragment = {
  __typename?: 'NewsArticlesComponent';
};

export type DynamicComponentNewsletterSignupComponentFragment = {
  __typename?: 'NewsletterSignupComponent';
};

export type DynamicComponentOfferButtonComponentFragment = {
  __typename?: 'OfferButtonComponent';
};

export type DynamicComponentOffersMapPlaceholderComponentFragment = {
  __typename?: 'OffersMapPlaceholderComponent';
};

export type DynamicComponentProductGalleryWithMapComponentFragment = {
  __typename?: 'ProductGalleryWithMapComponent';
  title: string;
  images: Array<{
    __typename?: 'ProductImageItem';
    productName: string;
    coordinates: {
      __typename?: 'GeoCoordinates';
      latitude: number;
      longitude: number;
    };
    image?: {
      __typename?: 'Image';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    } | null;
    preview?: {
      __typename?: 'Image';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    } | null;
    disrupters?: Array<{
      __typename?: 'DisrupterObject';
      text: string;
      fontColor?: string | null;
      backgroundColor?: string | null;
    }> | null;
  }>;
  routePoints?: Array<{
    __typename?: 'RoutePoint';
    name: string;
    coordinates: {
      __typename?: 'GeoCoordinates';
      latitude: number;
      longitude: number;
    };
  }> | null;
  relatedRoundtrips?: {
    __typename?: 'RelatedRoundtrips';
    coordinates?: {
      __typename?: 'GeoCoordinates';
      latitude: number;
      longitude: number;
    } | null;
    roundtrips?: Array<{
      __typename?: 'RelatedRoundtripItem';
      name: string;
      link: {
        __typename?: 'InternalLink';
        targetPageType: Types.PageType;
        uri: string;
      };
      image?: {
        __typename?: 'Image';
        alt: string;
        description?: string | null;
        copyright?: string | null;
        resized: Array<{
          __typename?: 'ResizedImage';
          url: string;
          id: string;
        }>;
      } | null;
    }> | null;
  } | null;
};

export type DynamicComponentProductRecommendationFragment = {
  __typename?: 'ProductRecommendation';
};

export type DynamicComponentRequestButtonComponentFragment = {
  __typename?: 'RequestButtonComponent';
  fontColor?: string | null;
  backgroundColor?: string | null;
  caption: string;
  link: {
    __typename?: 'ExternalLink';
    openMethod: Types.OpenLinkMethod;
    url: string;
    caption?: string | null;
  };
};

export type DynamicComponentResortTopHotelsComponentFragment = {
  __typename?: 'ResortTopHotelsComponent';
};

export type DynamicComponentSrlButtonPlaceholderComponentFragment = {
  __typename?: 'SrlButtonPlaceholderComponent';
};

export type DynamicComponentSrlMapPinsComponentFragment = {
  __typename?: 'SrlMapPinsComponent';
};

export type DynamicComponentStaticBlogRecommendationFragment = {
  __typename?: 'StaticBlogRecommendation';
  title: string;
  articles: Array<{
    __typename?: 'BlogArticleItem';
    leadText: string;
    mainTitle: string;
    tags?: Array<string> | null;
    date?: any | null;
    hero: {
      __typename?: 'Image';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
    author: {
      __typename?: 'EmployeeBox';
      name?: string | null;
      image?: {
        __typename?: 'Image';
        alt: string;
        description?: string | null;
        copyright?: string | null;
        resized: Array<{
          __typename?: 'ResizedImage';
          url: string;
          id: string;
        }>;
      } | null;
    };
    url: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
      objectId?: string | null;
    };
  }>;
};

export type DynamicComponentStaticGeoRecommendationFragment = {
  __typename?: 'StaticGeoRecommendation';
  name?: string | null;
  displayNumber: number;
  strictFontColor: string;
  strictBackgroundColor: string;
  items: Array<{
    __typename?: 'StaticGeoRecommendationItem';
    id: string;
    title: string;
    link: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
      objectId?: string | null;
    };
    image: {
      __typename?: 'Image';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
  }>;
};

export type DynamicComponentStaticProductRecommendationFragment = {
  __typename?: 'StaticProductRecommendation';
  name?: string | null;
  fontColor?: string | null;
  backgroundColor?: string | null;
  items: Array<{
    __typename?: 'StaticProductRecommendationItem';
    name: string;
    teaser: string;
    route: Array<string>;
    breadcrumbs?: Array<string> | null;
    bookmarked: boolean;
    link: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
      objectId?: string | null;
    };
    disrupters?: Array<{
      __typename?: 'DisrupterObject';
      text: string;
      fontColor?: string | null;
      backgroundColor?: string | null;
    }> | null;
    features: Array<{
      __typename?: 'ProductFeature';
      id: string;
      name: string;
      enabled?: boolean | null;
    }>;
    image: {
      __typename?: 'Image';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
    routePoints?: Array<{
      __typename?: 'RoutePoint';
      name: string;
      coordinates: {
        __typename?: 'GeoCoordinates';
        latitude: number;
        longitude: number;
      };
    }> | null;
  }>;
  productFilters?: Array<{
    __typename?: 'ProductFilter';
    objectId: string;
    objectName: string;
  }> | null;
};

export type DynamicComponentStaticThemeRecommendationFragment = {
  __typename?: 'StaticThemeRecommendation';
  name?: string | null;
  displayType?: string | null;
  strictFontColor: string;
  strictBackgroundColor: string;
  items: Array<{
    __typename?: 'StaticThemeRecommendationItem';
    name: string;
    link: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
      objectId?: string | null;
    };
    image: {
      __typename?: 'Image';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
  }>;
};

export type DynamicComponentTextComponentFragment = {
  __typename?: 'TextComponent';
  title: string;
  text: string;
  type: string;
  textWidth?: string | null;
  backgroundColor?: string | null;
  bottomPadding?: string | null;
  topPadding?: string | null;
  fontColor?: string | null;
  backgroundImage?: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  } | null;
};

export type DynamicComponentThemeRecommendationFragment = {
  __typename?: 'ThemeRecommendation';
};

export type DynamicComponentUspBoxesComponentFragment = {
  __typename?: 'UspBoxesComponent';
  uspTitle?: string | null;
  fontColor?: string | null;
  backgroundColor?: string | null;
  boxes: Array<{
    __typename?: 'UspBox';
    title?: string | null;
    subtitle?: string | null;
    text?: string | null;
    link?:
      | {
          __typename?: 'ExternalLink';
          openMethod: Types.OpenLinkMethod;
          url: string;
          caption?: string | null;
        }
      | {
          __typename?: 'InternalLink';
          caption?: string | null;
          uri: string;
          targetPageType: Types.PageType;
          objectId?: string | null;
        }
      | null;
    icon?: {
      __typename?: 'Image';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    } | null;
  }>;
};

export type DynamicComponentFragment =
  | DynamicComponentAccordionComponentFragment
  | DynamicComponentAgencyContactComponentFragment
  | DynamicComponentAgencyRecommendationFragment
  | DynamicComponentBd4TravelRecommendationGroupFragment
  | DynamicComponentBenefitsComponentFragment
  | DynamicComponentBlogArticleRecommendationFragment
  | DynamicComponentBrandsComponentFragment
  | DynamicComponentClimateComponentFragment
  | DynamicComponentContactModuleComponentFragment
  | DynamicComponentEmployeesComponentFragment
  | DynamicComponentFaqComponentFragment
  | DynamicComponentFlightWithPriceRecommendationFragment
  | DynamicComponentFlightWithoutPriceRecommendationFragment
  | DynamicComponentGeoChildrenComponentFragment
  | DynamicComponentGeoGalleryComponentFragment
  | DynamicComponentGeoRecommendationFragment
  | DynamicComponentHeroMediaGalleryFragment
  | DynamicComponentImageTextComponentFragment
  | DynamicComponentLinkListComponentFragment
  | DynamicComponentMarketingRecommendationFragment
  | DynamicComponentMarketingTeaserComponentFragment
  | DynamicComponentMediaGalleryFragment
  | DynamicComponentNewsArticlesComponentFragment
  | DynamicComponentNewsletterSignupComponentFragment
  | DynamicComponentOfferButtonComponentFragment
  | DynamicComponentOffersMapPlaceholderComponentFragment
  | DynamicComponentProductGalleryWithMapComponentFragment
  | DynamicComponentProductRecommendationFragment
  | DynamicComponentRequestButtonComponentFragment
  | DynamicComponentResortTopHotelsComponentFragment
  | DynamicComponentSrlButtonPlaceholderComponentFragment
  | DynamicComponentSrlMapPinsComponentFragment
  | DynamicComponentStaticBlogRecommendationFragment
  | DynamicComponentStaticGeoRecommendationFragment
  | DynamicComponentStaticProductRecommendationFragment
  | DynamicComponentStaticThemeRecommendationFragment
  | DynamicComponentTextComponentFragment
  | DynamicComponentThemeRecommendationFragment
  | DynamicComponentUspBoxesComponentFragment;

export const DynamicComponentFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'dynamicComponent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DynamicComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TextComponent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'text' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UspBoxesComponent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'usps' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FaqComponent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'faq' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'HeroMediaGallery' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'heroMediaGallery' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MediaGallery' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'mediaGallery' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StaticGeoRecommendation' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'staticGeoRecommendation' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StaticThemeRecommendation' },
            },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'long' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'staticThemeRecommendation' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MarketingRecommendation' },
            },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'long' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'marketingRecommendation' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StaticProductRecommendation' },
            },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'long' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'staticProductRecommendation' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ContactModuleComponent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'contactModule' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ProductGalleryWithMapComponent' },
            },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'long' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'productGalleryWithMap' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestButtonComponent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'requestButton' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AgencyRecommendation' },
            },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'long' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'agencyRecommendation' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AgencyContactComponent' },
            },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'long' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'agencyContactComponent' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StaticBlogRecommendation' },
            },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'long' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'staticBlogRecommendation' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BenefitsComponent' },
            },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'long' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'benefits' },
                },
              ],
            },
          },
        ],
      },
    },
    ...TextFragmentDoc.definitions,
    ...UspsFragmentDoc.definitions,
    ...FaqFragmentDoc.definitions,
    ...HeroMediaGalleryFragmentDoc.definitions,
    ...MediaGalleryFragmentDoc.definitions,
    ...StaticGeoRecommendationFragmentDoc.definitions,
    ...StaticThemeRecommendationFragmentDoc.definitions,
    ...MarketingRecommendationFragmentDoc.definitions,
    ...StaticProductRecommendationFragmentDoc.definitions,
    ...ContactModuleFragmentDoc.definitions,
    ...ProductGalleryWithMapFragmentDoc.definitions,
    ...RequestButtonFragmentDoc.definitions,
    ...AgencyRecommendationFragmentDoc.definitions,
    ...AgencyContactComponentFragmentDoc.definitions,
    ...StaticBlogRecommendationFragmentDoc.definitions,
    ...BenefitsFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const GetComponentsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetComponents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestContextInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ComponentsQueryInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'long' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: true },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'heroCriteria' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ImageCriteriaInput' },
                },
              },
            },
          },
          defaultValue: {
            kind: 'ListValue',
            values: [
              {
                kind: 'ObjectValue',
                fields: [
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'id' },
                    value: {
                      kind: 'StringValue',
                      value: '1x_desktop',
                      block: false,
                    },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'width' },
                    value: { kind: 'IntValue', value: '1920' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'height' },
                    value: { kind: 'IntValue', value: '590' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'resizingType' },
                    value: { kind: 'EnumValue', value: 'FILL_DOWN' },
                  },
                ],
              },
              {
                kind: 'ObjectValue',
                fields: [
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'id' },
                    value: {
                      kind: 'StringValue',
                      value: '1x_mobile',
                      block: false,
                    },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'width' },
                    value: { kind: 'IntValue', value: '1024' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'height' },
                    value: { kind: 'IntValue', value: '590' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'resizingType' },
                    value: { kind: 'EnumValue', value: 'FILL_DOWN' },
                  },
                ],
              },
            ],
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mediaPreview' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ImageCriteriaInput' },
                },
              },
            },
          },
          defaultValue: {
            kind: 'ListValue',
            values: [
              {
                kind: 'ObjectValue',
                fields: [
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'id' },
                    value: {
                      kind: 'StringValue',
                      value: '1x_desktop',
                      block: false,
                    },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'width' },
                    value: { kind: 'IntValue', value: '1170' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'height' },
                    value: { kind: 'IntValue', value: '632' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'resizingType' },
                    value: { kind: 'EnumValue', value: 'FILL_DOWN' },
                  },
                ],
              },
              {
                kind: 'ObjectValue',
                fields: [
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'id' },
                    value: {
                      kind: 'StringValue',
                      value: '1x_mobile',
                      block: false,
                    },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'width' },
                    value: { kind: 'IntValue', value: '414' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'height' },
                    value: { kind: 'IntValue', value: '224' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'resizingType' },
                    value: { kind: 'EnumValue', value: 'FILL_DOWN' },
                  },
                ],
              },
            ],
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mediaFull' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ImageCriteriaInput' },
                },
              },
            },
          },
          defaultValue: {
            kind: 'ListValue',
            values: [
              {
                kind: 'ObjectValue',
                fields: [
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'id' },
                    value: {
                      kind: 'StringValue',
                      value: '1x_desktop',
                      block: false,
                    },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'width' },
                    value: { kind: 'IntValue', value: '1920' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'height' },
                    value: { kind: 'IntValue', value: '1080' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'resizingType' },
                    value: { kind: 'EnumValue', value: 'FIT' },
                  },
                ],
              },
              {
                kind: 'ObjectValue',
                fields: [
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'id' },
                    value: {
                      kind: 'StringValue',
                      value: '1x_mobile',
                      block: false,
                    },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'width' },
                    value: { kind: 'IntValue', value: '850' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'height' },
                    value: { kind: 'IntValue', value: '850' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'resizingType' },
                    value: { kind: 'EnumValue', value: 'FIT' },
                  },
                ],
              },
            ],
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'marketingRecommendation' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ImageCriteriaInput' },
                },
              },
            },
          },
          defaultValue: {
            kind: 'ListValue',
            values: [
              {
                kind: 'ObjectValue',
                fields: [
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'id' },
                    value: {
                      kind: 'StringValue',
                      value: '1x_desktop',
                      block: false,
                    },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'width' },
                    value: { kind: 'IntValue', value: '1920' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'height' },
                    value: { kind: 'IntValue', value: '590' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'resizingType' },
                    value: { kind: 'EnumValue', value: 'FILL_DOWN' },
                  },
                ],
              },
              {
                kind: 'ObjectValue',
                fields: [
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'id' },
                    value: {
                      kind: 'StringValue',
                      value: '1x_mobile',
                      block: false,
                    },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'width' },
                    value: { kind: 'IntValue', value: '850' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'height' },
                    value: { kind: 'IntValue', value: '850' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'resizingType' },
                    value: { kind: 'EnumValue', value: 'FILL_DOWN' },
                  },
                ],
              },
            ],
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'galleryWithMapPreview' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ImageCriteriaInput' },
                },
              },
            },
          },
          defaultValue: {
            kind: 'ListValue',
            values: [
              {
                kind: 'ObjectValue',
                fields: [
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'id' },
                    value: {
                      kind: 'StringValue',
                      value: '1x_desktop',
                      block: false,
                    },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'width' },
                    value: { kind: 'IntValue', value: '582' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'height' },
                    value: { kind: 'IntValue', value: '500' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'resizingType' },
                    value: { kind: 'EnumValue', value: 'FILL_DOWN' },
                  },
                ],
              },
              {
                kind: 'ObjectValue',
                fields: [
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'id' },
                    value: {
                      kind: 'StringValue',
                      value: '1x_mobile',
                      block: false,
                    },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'width' },
                    value: { kind: 'IntValue', value: '414' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'height' },
                    value: { kind: 'IntValue', value: '356' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'resizingType' },
                    value: { kind: 'EnumValue', value: 'FILL_DOWN' },
                  },
                ],
              },
            ],
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'galleryWithMapFull' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ImageCriteriaInput' },
                },
              },
            },
          },
          defaultValue: {
            kind: 'ListValue',
            values: [
              {
                kind: 'ObjectValue',
                fields: [
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'id' },
                    value: {
                      kind: 'StringValue',
                      value: '1x_desktop',
                      block: false,
                    },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'width' },
                    value: { kind: 'IntValue', value: '1920' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'height' },
                    value: { kind: 'IntValue', value: '632' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'resizingType' },
                    value: { kind: 'EnumValue', value: 'FIT' },
                  },
                ],
              },
              {
                kind: 'ObjectValue',
                fields: [
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'id' },
                    value: {
                      kind: 'StringValue',
                      value: '1x_mobile',
                      block: false,
                    },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'width' },
                    value: { kind: 'IntValue', value: '850' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'height' },
                    value: { kind: 'IntValue', value: '528' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'resizingType' },
                    value: { kind: 'EnumValue', value: 'FIT' },
                  },
                ],
              },
            ],
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'blogCriteria' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ImageCriteriaInput' },
                },
              },
            },
          },
          defaultValue: {
            kind: 'ListValue',
            values: [
              {
                kind: 'ObjectValue',
                fields: [
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'id' },
                    value: {
                      kind: 'StringValue',
                      value: '1040w_desktop',
                      block: false,
                    },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'width' },
                    value: { kind: 'IntValue', value: '497' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'height' },
                    value: { kind: 'IntValue', value: '590' },
                  },
                ],
              },
              {
                kind: 'ObjectValue',
                fields: [
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'id' },
                    value: {
                      kind: 'StringValue',
                      value: '1240w_desktop',
                      block: false,
                    },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'width' },
                    value: { kind: 'IntValue', value: '695' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'height' },
                    value: { kind: 'IntValue', value: '590' },
                  },
                ],
              },
              {
                kind: 'ObjectValue',
                fields: [
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'id' },
                    value: {
                      kind: 'StringValue',
                      value: '1495w_desktop',
                      block: false,
                    },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'width' },
                    value: { kind: 'IntValue', value: '952' },
                  },
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: 'height' },
                    value: { kind: 'IntValue', value: '640' },
                  },
                ],
              },
            ],
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'components' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requestContext' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'dynamicComponent' },
                },
              ],
            },
          },
        ],
      },
    },
    ...DynamicComponentFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetComponentsQuery__
 *
 * To run a query within a React component, call `useGetComponentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComponentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComponentsQuery({
 *   variables: {
 *      context: // value for 'context'
 *      input: // value for 'input'
 *      long: // value for 'long'
 *      heroCriteria: // value for 'heroCriteria'
 *      mediaPreview: // value for 'mediaPreview'
 *      mediaFull: // value for 'mediaFull'
 *      marketingRecommendation: // value for 'marketingRecommendation'
 *      galleryWithMapPreview: // value for 'galleryWithMapPreview'
 *      galleryWithMapFull: // value for 'galleryWithMapFull'
 *      blogCriteria: // value for 'blogCriteria'
 *   },
 * });
 */
export function useGetComponentsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetComponentsQuery,
    GetComponentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetComponentsQuery,
    GetComponentsQueryVariables
  >(GetComponentsDocument, options);
}
export function useGetComponentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetComponentsQuery,
    GetComponentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetComponentsQuery,
    GetComponentsQueryVariables
  >(GetComponentsDocument, options);
}
export type GetComponentsQueryHookResult = ReturnType<
  typeof useGetComponentsQuery
>;
export type GetComponentsLazyQueryHookResult = ReturnType<
  typeof useGetComponentsLazyQuery
>;
export type GetComponentsQueryResult = Apollo.QueryResult<
  GetComponentsQuery,
  GetComponentsQueryVariables
>;
