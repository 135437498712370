import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { LinkFragmentFragmentDoc } from 'graphql/link/Link.generated';
import { ResizedImageWithMetaFragmentDoc } from 'graphql/image/ResizedImageWithMeta.generated';
export type UspsFragment = {
  __typename?: 'UspBoxesComponent';
  uspTitle?: string | null;
  fontColor?: string | null;
  backgroundColor?: string | null;
  boxes: Array<{
    __typename?: 'UspBox';
    title?: string | null;
    subtitle?: string | null;
    text?: string | null;
    link?:
      | {
          __typename?: 'ExternalLink';
          openMethod: Types.OpenLinkMethod;
          url: string;
          caption?: string | null;
        }
      | {
          __typename?: 'InternalLink';
          caption?: string | null;
          uri: string;
          targetPageType: Types.PageType;
          objectId?: string | null;
        }
      | null;
    icon?: {
      __typename?: 'Image';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    } | null;
  }>;
};

export type UspFragment = {
  __typename?: 'UspBox';
  title?: string | null;
  subtitle?: string | null;
  text?: string | null;
  link?:
    | {
        __typename?: 'ExternalLink';
        openMethod: Types.OpenLinkMethod;
        url: string;
        caption?: string | null;
      }
    | {
        __typename?: 'InternalLink';
        caption?: string | null;
        uri: string;
        targetPageType: Types.PageType;
        objectId?: string | null;
      }
    | null;
  icon?: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  } | null;
};

export const UspFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'usp' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UspBox' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'linkFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '150' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '110' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '150' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '110' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
        ],
      },
    },
    ...LinkFragmentFragmentDoc.definitions,
    ...ResizedImageWithMetaFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const UspsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'usps' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UspBoxesComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uspTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'boxes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'usp' },
                },
              ],
            },
          },
        ],
      },
    },
    ...UspFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
