import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { InternalLinkFragmentFragmentDoc } from 'graphql/link/InternalLink.generated';
import { ResizedImageWithMetaFragmentDoc } from 'graphql/image/ResizedImageWithMeta.generated';
export type StaticThemeRecommendationFragment = {
  __typename?: 'StaticThemeRecommendation';
  name?: string | null;
  displayType?: string | null;
  strictFontColor: string;
  strictBackgroundColor: string;
  items: Array<{
    __typename?: 'StaticThemeRecommendationItem';
    name: string;
    link: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
      objectId?: string | null;
    };
    image: {
      __typename?: 'Image';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
  }>;
};

export type StaticThemeRecommendationItemFragment = {
  __typename?: 'StaticThemeRecommendationItem';
  name: string;
  link: {
    __typename?: 'InternalLink';
    caption?: string | null;
    uri: string;
    targetPageType: Types.PageType;
    objectId?: string | null;
  };
  image: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
};

export const StaticThemeRecommendationItemFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'staticThemeRecommendationItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StaticThemeRecommendationItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'internalLinkFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '364' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '315' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '468' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '388' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
        ],
      },
    },
    ...InternalLinkFragmentFragmentDoc.definitions,
    ...ResizedImageWithMetaFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const StaticThemeRecommendationFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'staticThemeRecommendation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StaticThemeRecommendation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'staticThemeRecommendationItem',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'strictFontColor' },
            name: { kind: 'Name', value: 'fontColor' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'strictBackgroundColor' },
            name: { kind: 'Name', value: 'backgroundColor' },
          },
        ],
      },
    },
    ...StaticThemeRecommendationItemFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
