import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { TextFragmentDoc } from './Text.generated';
export type FaqFragment = {
  __typename?: 'FaqComponent';
  items: Array<{
    __typename?: 'TextComponent';
    title: string;
    text: string;
    type: string;
    textWidth?: string | null;
    backgroundColor?: string | null;
    bottomPadding?: string | null;
    topPadding?: string | null;
    fontColor?: string | null;
    backgroundImage?: {
      __typename?: 'Image';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    } | null;
  }>;
};

export const FaqFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'faq' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FaqComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'text' },
                },
              ],
            },
          },
        ],
      },
    },
    ...TextFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
